<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="认证审核"
        >
          <template slot="extra" v-if="activeTab==2">
                      <a-button
                        html-type="submit"
                        style="margin-left: 16px"
                        @click="getExport(1)"
                        size="small"
                      >
                        导出
                      </a-button>
                    </template>
        </a-page-header>
      </div>
      <div ref="tabs">
        <a-tabs tabPosition="top" @tabClick="callback">
          <a-tab-pane
            v-for="i in checkTitle"
            :key="i.status"
            :tab="i.name"
          ></a-tab-pane>
        </a-tabs>
      </div>
      <a-table
        :columns="columns"
        :data-source="credentialsList"
        :pagination="false"
        :row-key="(record) => record.id"
        :scroll="{ x: 1200, y: table_h }"
        size="middle"
        ref="tableList"
        class="components-table-demo-nested"
        @change="handleTableChange"
      >
        <span slot="avatar" slot-scope="avatar">
          <img :src="avatar" alt="" />
        </span>
        <span slot="gender" slot-scope="gender">
          {{ gender == 0 ? "未知" : "" }}
          {{ gender == 1 ? "男性" : "" }}
          {{ gender == 2 ? "女性" : "" }}
        </span>
        <!-- <span slot="platform" slot-scope="platform">
          <span v-if="platform == 'dy'">抖音</span>
          <span v-if="platform == 'ks'">快手</span>
          <span v-if="platform == 'xhs'">小红书</span>
          <span v-if="platform == 'wx'">视频号</span>
          <span v-if="platform == 'b'">B站</span>
        </span> -->
        <span slot="anchor_type" slot-scope="anchor_type">
          <span v-for="(item, index) in anchor_type" :key="item">
            <span v-if="index != 0">，</span>
            <span>{{ item }}</span>
          </span>
        </span>
        <span slot="screenshot" slot-scope="screenshot">
          <img :src="screenshot" alt="" class="screenshot" v-image-preview />
        </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button
            class="editable-add-btn"
            v-for="btn in btns"
            :key="btn"
            @click="handel(btn, num)"
            size="small"
            style="margin: 5px"
          >
            {{ btn }}
          </a-button>
        </span>
        <!-- <template slot="footer"> Footer </template> -->
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      //   form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      //   addform: this.$form.createForm(this, { name: "city_add" }),
      columns: [
        {
          title: "用户昵称",
          key: "users_nickname",
          dataIndex: "users_nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          key: "users_mobile",
          dataIndex: "users_mobile",
          align: "center",
        },
        {
          title: "平台",
          key: "platform_name",
          dataIndex: "platform_name",
          align: "center",
        },

        {
          title: "平台昵称",
          key: "nickname",
          dataIndex: "nickname",
          align: "center",
        },
        {
          title: "平台头像",
          key: "avatar",
          dataIndex: "avatar",
          scopedSlots: { customRender: "avatar" },
          align: "center",
        },
        {
          title: "性别",
          key: "gender",
          dataIndex: "gender",
          scopedSlots: { customRender: "gender" },
          align: "center",
        },
        {
          title: "主播类型",
          key: "anchor_type",
          dataIndex: "anchor_type",
          scopedSlots: { customRender: "anchor_type" },
          align: "center",
        },
        {
          title: "平台粉丝数",
          key: "fans_count",
          dataIndex: "fans_count",
          align: "center",
          sorter: true,
        },
        {
          title: "平台主页截图",
          key: "screenshot",
          dataIndex: "screenshot",
          scopedSlots: { customRender: "screenshot" },
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
          sorter: true,
        },
        {
          title: "更新时间",
          key: "updated_at",
          dataIndex: "updated_at",
          align: "center",
          sorter: true,
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
          width: 100,
          fixed: "right",
        },
      ],
      checkTitle: [
        { name: "待审核", status: 1 },
        { name: "审核成功", status: 2 },
        { name: "审核失败", status: 3 },
      ],
      activeTab: 1,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      credentialsList: [],
      sort_model: "",
      sort_by: "",
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({ status: 1 });
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.tabs.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      console.log(filters);
      console.log(sorter);
      this.currentPage = 1;
      if (!sorter.order) {
        this.sort_by = "";
        this.sort_model = "";
      } else {
        if (sorter.order == "ascend") {
          this.sort_model = "asc";
        } else {
          this.sort_model = "desc";
        }
        this.sort_by = sorter.field;
      }
      this.getSearchList({ status: this.activeTab });
      // const pager = { ...this.pagination };
      // pager.current = pagination.current;
      // this.pagination = pager;
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
    },
    callback(val) {
      this.activeTab = val;
      this.currentPage = 1;
      
        // this.sort_by = "";
        // this.sort_model = "";
      if (val != 1) {
        this.columns = [
          {
            title: "用户昵称",
            key: "users_nickname",
            dataIndex: "users_nickname",
            align: "center",
          },
          {
            title: "用户手机号",
            key: "users_mobile",
            dataIndex: "users_mobile",
            align: "center",
          },
          {
            title: "平台",
            key: "platform_name",
            dataIndex: "platform_name",
            align: "center",
          },

          {
            title: "平台昵称",
            key: "nickname",
            dataIndex: "nickname",
            align: "center",
          },
          {
            title: "平台头像",
            key: "avatar",
            dataIndex: "avatar",
            scopedSlots: { customRender: "avatar" },
            align: "center",
          },
          {
            title: "性别",
            key: "gender",
            dataIndex: "gender",
            scopedSlots: { customRender: "gender" },
            align: "center",
          },
          {
            title: "主播类型",
            key: "anchor_type",
            dataIndex: "anchor_type",
            scopedSlots: { customRender: "anchor_type" },
            align: "center",
          },
          {
            title: "平台粉丝数",
            key: "fans_count",
            dataIndex: "fans_count",
            align: "center",
          sorter: true,
          },
          {
            title: "平台主页截图",
            key: "screenshot",
            dataIndex: "screenshot",
            scopedSlots: { customRender: "screenshot" },
            align: "center",
          },
          {
            title: "创建时间",
            key: "created_at",
            dataIndex: "created_at",
            align: "center",
          },
          {
            title: "更新时间",
            key: "updated_at",
            dataIndex: "updated_at",
            align: "center",
          },
        ];
      } else {
        this.columns = [
          {
            title: "用户昵称",
            key: "users_nickname",
            dataIndex: "users_nickname",
            align: "center",
          },
          {
            title: "用户手机号",
            key: "users_mobile",
            dataIndex: "users_mobile",
            align: "center",
          },
          {
            title: "平台",
            key: "platform_name",
            dataIndex: "platform_name",
            align: "center",
          },

          {
            title: "平台昵称",
            key: "nickname",
            dataIndex: "nickname",
            align: "center",
          },
          {
            title: "平台头像",
            key: "avatar",
            dataIndex: "avatar",
            scopedSlots: { customRender: "avatar" },
            align: "center",
          },
          {
            title: "性别",
            key: "gender",
            dataIndex: "gender",
            scopedSlots: { customRender: "gender" },
            align: "center",
          },
          {
            title: "主播类型",
            key: "anchor_type",
            dataIndex: "anchor_type",
            scopedSlots: { customRender: "anchor_type" },
            align: "center",
          },
          {
            title: "平台粉丝数",
            key: "fans_count",
            dataIndex: "fans_count",
            align: "center",
          sorter: true,
          },
          {
            title: "平台主页截图",
            key: "screenshot",
            dataIndex: "screenshot",
            scopedSlots: { customRender: "screenshot" },
            align: "center",
          },
          {
            title: "创建时间",
            key: "created_at",
            dataIndex: "created_at",
            align: "center",
          },
          {
            title: "更新时间",
            key: "updated_at",
            dataIndex: "updated_at",
            align: "center",
          },
          {
            title: "操作",
            key: "btns",
            dataIndex: "btns",
            scopedSlots: { customRender: "btns" },
            align: "center",
            width: 100,
            fixed: "right",
          },
        ];
      }
      this.getSearchList({ status: val });
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      // that.form.validateFields((error, values) => {
      that.getSearchList({ status: this.activeTab });
      // });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      if(that.sort_by){
        data.sort_by=that.sort_by
      }
      if(that.sort_model){
      data.sort_model=that.sort_model
      }
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/platformAuth/log/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var credentialsList = res.data.data;
            for (var i = 0; i < credentialsList.length; i++) {
              credentialsList[i].avatar = credentialsList[i].info.avatar;
              credentialsList[i].nickname = credentialsList[i].info.nickname;
              credentialsList[i].fans_count =
                credentialsList[i].info.fans_count;
              credentialsList[i].gender = credentialsList[i].info.gender;
              credentialsList[i].anchor_type =
                credentialsList[i].info.anchor_type;
              credentialsList[i].screenshot =
                credentialsList[i].info.screenshot;
              if (data.status == 1) {
                credentialsList[i].btns = ["通过", "不通过"];
              } else {
                credentialsList[i].btns = [];
              }
            }
            that.changeTableH();
            that.credentialsList = credentialsList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },

    // 操作（通过、不通过）
    handel(btn, num) {
      var that = this;
      var data = {};
      data.id = num.id;
      if (btn == "通过") {
        data.status = 2;
      }
      if (btn == "不通过") {
        data.status = 3;
      }
      that.$confirm({
        title: "提示",
        content: "认证审核：" + btn,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
          that.axios
            .get("/admin/platformAuth/log/modify", { params: data })
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("审核成功");
                that.getSearchList({ status: that.activeTab });
              }
            });
        },
      });
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
      if (this.export == 1) {
        var myObj = {
          method: "get",
          url: "/admin/usersAuthExport",
          fileName: "认证信息.csv",
        };
        this.$exportMethod(myObj);
      }
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      this.export = 0;
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
/* .ant-tabs {
  flex-grow: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.ant-tabs::-webkit-scrollbar {
  width: 0 !important;
} */
table img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
table .screenshot {
  width: 60px;
  height: auto;
  border-radius: 0;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-body div {
  text-align: center;
  margin: 0 20px;
}
.ant-modal-body img {
  width: 150px;
}
.ant-modal-body div {
  width: 100%;
  text-align: left;
  margin: 0;
}
/* .section.ant-layout .container .ant-table-wrapper {
  overflow: scroll;
} */
.section.ant-layout .container .ant-table-wrapper::-webkit-scrollbar {
  width: 0;
}
/deep/.vue-directive-image-previewer-img {
  width: auto;
}
</style>